import React from 'react';
import {
  faCheckSquare,
  faFile,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Link } from 'gatsby';

interface ResourceCardTypePillInterface {
  type: string;
}

export const ResourceCardTypePill = (props: ResourceCardTypePillInterface) => {
  const classNames = classnames({
    'rounded-full px-3 py-1 text-sm sans-serif': true,
    'bg-blue-200 text-primary': props.type === 'Assessments',
    'bg-primary text-blue-100': props.type === 'Videos',
    'bg-gray-200 text-gray-800': props.type === 'Curricular Materials',
    'bg-gray-500 text-gray-100': props.type === 'Reports'
  });

  const icon =
    props.type === 'Assessments'
      ? faCheckSquare
      : props.type === 'Videos'
      ? faVideo
      : props.type === 'Curricular Materials'
      ? faFile
      : faFile;
  return (
    <span className={classNames}>
      <span className="mr-2 opacity-60">
        <FontAwesomeIcon icon={icon} />
      </span>
      {props.type}
    </span>
  );
};

interface ResourceCardProps {
  title: string;
  type: string;
  image: any;
  link: string;
}

const ResourceCard = (props: ResourceCardProps) => {
  return (
    <Link to={props.link} className="w-72 rounded-lg bg-white shadow-lg">
      <img className="w-72 rounded-t-lg" src={props.image} alt={props.title}/>
      <div className="p-6">
        <ResourceCardTypePill type={props.type} />
        <p className="text-primary font-bold mt-10 text-lg">{props.title}</p>
      </div>
    </Link>
  );
};

export default ResourceCard;
